
import Breadcrumb from '@/common/breadcrumb.vue'
import { newsDetail } from '@/api/news'
import { useRoute } from 'vue-router'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'newsDetail',
  components: {
    Breadcrumb
  },
  setup () {
    const route = useRoute()
    const detail = ref({})
    const secondStage = ref({
      title: '新闻资讯',
      path: '/news',
      isJump: true
    })
    const thirdStage = ref({
      title: '',
      path: '/newsDetail',
      isJump: false
    })
    function toLink (link: string) {
      window.open(link)
    }
    function createMeta (name: string, content: string) {
      const metaEl: any = document.querySelector(`meta[name=${name}]`)
      metaEl.setAttribute('content', content)
    }
    async function getDetail () {
      const params = {
        newsId: route.query.newsId
      }
      const res: any = await newsDetail(params)
      if (res.status === '1') {
        detail.value = res.data
        thirdStage.value.title = res.data.title
        document.title = res.data.title + '-慧点政策'
        if (res.data.keywords) {
          createMeta('keywords', res.data.keywords)
        }
        if (res.data.summary) {
          createMeta('description', res.data.summary)
        }
      }
    }
    getDetail()
    return {
      secondStage,
      thirdStage,
      detail,
      toLink
    }
  }
})
