<template>
  <div class="newsDetail">
    <div class="banner"></div>
    <section class="main_width">
      <div class="breadcrumb_con">
        <Breadcrumb :secondStage="secondStage" :thirdStage="thirdStage" />
      </div>
      <div class="header">
        <h1 class="title">
          {{ detail.title }}
        </h1>
        <div class="subhead">
          <div class="date">{{ detail.releaseDate }}</div>
          <div class="source" v-if="detail.source">{{ detail.source }}</div>
        </div>
      </div>
      <div class="detail_con">
        <div class="content" v-html="detail.content"></div>
        <div class="link" v-if="detail.webSite">
          原文链接：<span class="light" @click="toLink(detail.webSite)">{{
            detail.webSite
          }}</span>
        </div>
        <!-- <ul class="tagList">
          <li># 意外险</li>
          <li># 成人保险</li>
        </ul> -->
      </div>
      <!-- <div class="toggle_con">
        <div class="pre_con">
          <div class="icon"></div>
          <div class="txt">上一篇</div>
          <div class="title">
            深圳金服：中小企业过桥资金可申请深圳贴息深圳金服：中小企业过桥资金可申请深圳贴息
          </div>
        </div>
        <div class="next_con">
          <div class="title">
            深圳金服：中小企业过桥资金可申请深圳贴息深圳金服：中小企业过桥资金可申请深圳贴息
          </div>
          <div class="txt">下一篇</div>
          <div class="icon"></div>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script lang="ts">
import Breadcrumb from '@/common/breadcrumb.vue'
import { newsDetail } from '@/api/news'
import { useRoute } from 'vue-router'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'newsDetail',
  components: {
    Breadcrumb
  },
  setup () {
    const route = useRoute()
    const detail = ref({})
    const secondStage = ref({
      title: '新闻资讯',
      path: '/news',
      isJump: true
    })
    const thirdStage = ref({
      title: '',
      path: '/newsDetail',
      isJump: false
    })
    function toLink (link: string) {
      window.open(link)
    }
    function createMeta (name: string, content: string) {
      const metaEl: any = document.querySelector(`meta[name=${name}]`)
      metaEl.setAttribute('content', content)
    }
    async function getDetail () {
      const params = {
        newsId: route.query.newsId
      }
      const res: any = await newsDetail(params)
      if (res.status === '1') {
        detail.value = res.data
        thirdStage.value.title = res.data.title
        document.title = res.data.title + '-慧点政策'
        if (res.data.keywords) {
          createMeta('keywords', res.data.keywords)
        }
        if (res.data.summary) {
          createMeta('description', res.data.summary)
        }
      }
    }
    getDetail()
    return {
      secondStage,
      thirdStage,
      detail,
      toLink
    }
  }
})
</script>

<style lang="scss" scoped>
@mixin dis_play() {
  display: flex;
  align-items: center;
}
@mixin one_line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin bg_url($url) {
  background: url('~assets/images/news/' + $url) no-repeat;
}
div {
  user-select: text;
}
/deep/ a {
  color: rgba(51, 51, 51, 0.5);
  &:hover {
    color: #66b1ff;
    text-decoration: underline;
  }
}
.newsDetail {
  padding-top: 103px;
  .banner {
    width: 100%;
    height: 380px;
    @include bg_url('banner.png');
    background-position: 50%;
    background-size: cover;
  }
  .breadcrumb_con {
    padding: 38px 0;
    border-bottom: 1px solid #eaeaea;
  }
  .header {
    margin-top: 50px;
    .title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 40px;
      color: #333333;
    }
    .subhead {
      @include dis_play();
      margin-top: 18px;
      .date {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        margin-right: 20px;
      }
      .source {
        background: #ffffff;
        border: 1px solid #e5ba71;
        font-size: 20px;
        color: #e5ba71;
        padding: 5px 16px;
      }
    }
  }
  .detail_con {
    margin-top: 62px;
    padding: 68px 0 78px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    .content {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 41px;
      color: #333333;
    }
    .link {
      margin-top: 20px;
      .light {
        color: #66b1ff;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .tagList {
      @include dis_play();
      margin-top: 58px;
      li {
        width: 102px;
        height: 34px;
        background: #f5f5f5;
        font-size: 18px;
        font-family: Microsoft YaHei;
        color: #e5ba71;
        margin-right: 12px;
        @include dis_play();
        justify-content: center;
      }
    }
  }
  .toggle_con {
    @include dis_play();
    justify-content: space-between;
    margin: 65px 0;
    .pre_con,
    .next_con {
      @include dis_play();
      cursor: pointer;
      font-family: Microsoft YaHei;
      font-size: 18px;
      color: #333333;
      .icon {
        width: 64px;
        height: 64px;
      }
      .txt {
        font-weight: bold;
      }
      .title {
        width: 360px;
        @include one_line();
      }
      &:hover {
        color: #e5ba71;
      }
    }
    .pre_con {
      .icon {
        @include bg_url('arrowLeft.png');
      }
      .txt {
        margin-left: 15px;
      }
      .title {
        margin-left: 32px;
      }
      &:hover {
        .icon {
          @include bg_url('arrowLeft_active.png');
        }
      }
    }
    .next_con {
      .icon {
        @include bg_url('arrowRight.png');
      }
      .txt {
        margin: 0 17px;
      }
      &:hover {
        .icon {
          @include bg_url('arrowRight_active.png');
        }
      }
    }
  }
}
</style>
